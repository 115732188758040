<template>
  <a-card title="24小时进出车辆统计">
    <div id="myEcharts" style="height: 400px"></div>
  </a-card>
</template>

<script>
import { onMounted, onUnmounted, ref, inject } from "vue";
import { parkingRecord24Hours } from "@/api/dashboardApi";
import useMessage from "@/hooks/useMessage";

export default {
  name: "LeftCarInOut",
  setup() {
    const loading = ref(false);
    const { showErr } = useMessage();
    const echarts = inject("ec");
    let myEcharts = ref(null);
    onMounted(async () => {
      myEcharts.value = echarts.init(document.getElementById("myEcharts"));
      myEcharts.value.setOption(options());
      loadData();
      window.onresize = () => {
        myEcharts.value.resize();
      };
    });
    onUnmounted(() => {
      myEcharts.value.dispose();
      myEcharts.value = null;
    });
    const options = () => {
      return {
        title: {
          // text: "24小时停车",
          textStyle: {
            // 文字颜色
            color: "rgb(3, 153, 204)",
            // 字体风格,'normal','italic','oblique'
            fontStyle: "normal",
            // 字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: "bold",
            // 字体系列
            fontFamily: "sans-serif",
            // 字体大小
            fontSize: 18,
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        color: ["#FF8C00", "#32CD32"],
        legend: {
          x: "right",
          data: [
            {
              name: "车辆进场",
              textStyle: {
                color: "#FF8C00",
              },
            },
            {
              name: "车辆离场",
              textStyle: {
                color: "#32CD32",
              },
            },
          ],
          textStyle: {
            color: "white",
            fontSize: 14,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgb(0, 204, 255)", // 横坐标线的颜色
            },
          },
          axisLabel: {
            textStyle: {
              // color: "white", // 横坐标字体颜色
            },
          },
          axisTick: {
            interval: 1,
          },
          type: "category",
          data: [],
        },
        yAxis: {
          name: "辆",
          type: "value",
          splitLine: {
            // 分隔线
            // show: false,
          },
          axisLine: {
            lineStyle: {
              color: "rgb(0, 204, 255)", // 纵坐标线的颜色
            },
          },
          axisLabel: {
            textStyle: {
              // color: "white", // 纵坐标字体颜色
            },
          },
        },
        series: [],
      };
    };
    const setOptions = (inData, outData, xData) => {
      let inRecord = {
        name: "车辆进场",
        type: "line",
        data: inData,
      };
      let outRecord = {
        name: "车辆离场",
        type: "line",
        data: outData,
      };
      let option = {
        series: [inRecord, outRecord],
        xAxis: {
          data: xData.sort((a, b) => {
            return b < a ? 1 : -1; // 升序
          }),
        },
      };
      myEcharts.value.setOption(option);
    };
    const loadData = async () => {
      loading.value = true;
      try {
        let r = await parkingRecord24Hours();
        let xData = [];
        let inData = [];
        let outData = [];
        r.data.forEach(item => {
          xData.push(item.date);
          let p1 = {
            name: item.date,
            value: item.carInNum,
          };
          let p2 = {
            name: item.date,
            value: item.carOutNum,
          };
          inData.push(p1);
          outData.push(p2);


          inData.sort((a,b)=>{
            return a.name > b.name ? 1 : -1
          })

          outData.sort((a,b)=>{
            return a.name > b.name ? 1 : -1
          })
          setOptions(inData, outData, xData);
        });

        loading.value = false;
      } catch (e) {
        loading.value = false;
        showErr(e);
      }
    };

    return {
      echarts,
      options,
      loading,
      loadData,
      setOptions,
      myEcharts,
    };
  },
};
</script>
